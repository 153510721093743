<template>
  <div>
    <!-- Header -->
    <div class="header">
      <!-- Logo -->
      <div class="header-left">
        <router-link :to="{ name: 'UserManagement' }" class="logo">
          <img :src="setImg('assets/images/logo.png')" alt="Logo" />
        </router-link>
        <router-link :to="{ name: 'Admin' }" class="logo logo-small">
          <img
            :src="setImg('assets/images/logo-small.png')"
            alt="Logo"
            width="30"
            height="30"
          />
        </router-link>
      </div>
      <!-- /Logo -->
<a href="javascript:void(0);" id="toggle_btn">
                <i class="fe fe-text-align-left"></i>
              </a>

      <!-- Mobile Menu Toggle -->
      <a class="mobile_btn" id="mobile_btn">
        <i class="fa fa-bars"></i>
      </a>
      <!-- /Mobile Menu Toggle -->

      <!-- Header Right Menu -->
      <ul class="nav user-menu">
        <!-- Notifications -->
        <li class="nav-item dropdown noti-dropdown">
          <a href="#" @click="doLogout" class="btn btn-primary float-right mt-2">Logout</a>
        </li>
        <!-- /Notifications -->
      </ul>
      <!-- /Header Right Menu -->
    </div>
    <!-- /Header -->
  </div>
</template>

<script>
import Export from "../../vue.config";
import {mapActions} from "vuex";

export default {
  name: "AdminHeader",
  components: {},

  data() {
    return {
      app_url: Export.publicPath,
    };
  },

  methods: {
    ...mapActions("Auth", [
      "logout",
    ]),
    doLogout() {
      this.logout();
      this.$router.push({ name: 'Admin' })
    }
  },
};
</script>

<style>
</style>
