<template>
  <div>
    <!-- Sidebar -->
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <div class="widget-profile pro-widget-content">
            <!--<a href="javascript:void(0);" id="toggle_btn">
                <i class="fe fe-text-align-left"></i>
              </a>-->
            <div class="profile-info-widget d-flex">
              <a href="#" class="booking-doc-img">
                <img
                  :src="profile.profile_pic"
                  alt="User Image"
                  v-if="profile"
                />
              </a>

              <div class="profile-det-info" v-if="profile">
                <h3>{{ profile.name }}</h3>
              </div>
            </div>
          </div>
          <ul>
            <li :class="currentRouteName('UserManagement')">
              <router-link :to="{ name: 'UserManagement' }">
                <i class="fe fe-home"></i>
                <span>User Management</span>
              </router-link>
            </li>

            <!--  -->
            <li>
              <div class="dropright">
                <a class="dropdown-toggle submenu" data-toggle="dropdown">
                  <i class="fa fa-tasks"></i>
                  Plan Management
                </a>
                <div class="dropdown-menu">
                  <router-link
                    :to="{ name: 'Individual Plans' }"
                    class="dropdown-item"
                  >
                    <i class="fa fa-tasks"></i>
                    <span>Individual</span>
                  </router-link>
                  <router-link :to="{ name: 'PlanManagement' }"  class="dropdown-item">
                    <i class="fa fa-tasks"></i><span>Corporate</span>
                  </router-link>
                </div>
              </div>
            </li>
            <!--  -->

            <!-- <li :class="currentRouteName('Individual Plans')">
              <router-link :to="{ name: 'Individual Plans' }">
                <i class="fa fa-tasks"></i>
                <span>Individual Plan Management</span>
                </router-link
              >
            </li>
            <li :class="currentRouteName('PlanManagement')">
              <router-link :to="{ name: 'PlanManagement' }">
                <i class="fa fa-tasks"></i> <span> Plan Management</span>
               </router-link
              >
            </li> -->
            <li :class="currentRouteName('CorporateSubscriptions')">
              <router-link :to="{ name: 'CorporateSubscriptions' }">
                <i class="fa fa-tasks"></i>
                <span> Corporate Subscriptions</span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>

            <!-- to be replaced later with ul dropdown -->
            <!--<li>
              <a href="#">
                <i class="fa fa-bold"></i> <span> Content Management</span>
                <span class="menu-arrow"></span</a>
            </li>-->

            <li :class="currentRouteName('Trainers')">
              <router-link :to="{ name: 'Trainers' }">
                <i class="las la-chalkboard-teacher"></i> <span> Trainers</span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>

            <li :class="currentRouteName('Tags')">
              <router-link :to="{ name: 'Tags' }">
                <i class="fa fa-tags"></i> <span> Tags</span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>

            <li :class="currentRouteName('Categories')">
              <router-link :to="{ name: 'Categories' }">
                <i class="fa fa-list-alt"></i> <span> Categories</span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>

            <li :class="currentRouteName('Videos')">
              <router-link :to="{ name: 'Videos' }">
                <i class="fe fe-video"></i> <span> Videos</span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>

            <li :class="currentRouteName('Ingredients')">
              <router-link :to="{ name: 'Ingredients' }">
                <i class="las la-pizza-slice"></i> <span> Ingredients</span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>

            <li :class="currentRouteName('Recipes')">
              <router-link :to="{ name: 'Recipes' }">
                <i class="fe fe-cutlery"></i> <span> Recipes</span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>

            <!-- <li class="submenu">
              <a href="#"
                ><i class="fe fe-document"></i>
                <span> Content Management </span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li><a href="trainers.html">Trainers</a></li>
                <li><a href="category.html">Categories</a></li>
                <li><a href="video.html">Videos</a></li>
                <li><a href="recipes.html">Recipes</a></li>
              </ul>
            </li> -->

            <li :class="currentRouteName('SupportTicket')">
              <router-link :to="{ name: 'SupportTicket' }">
                <i class="fa fa-support"></i> <span> Support Tickets </span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>
            <li :class="currentRouteName('AdminProfile')">
              <router-link :to="{ name: 'AdminProfile' }">
                <i class="fa fa-user"></i> <span> Profile </span>
                <!--<span class="menu-arrow"></span--></router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Sidebar -->
  </div>
</template>

<script>
import Export from "../../vue.config";
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";

import Cookies from "js-cookie";
import { TOKEN } from "../store/config/constants";

export default {
  data() {
    return {
      app_url: Export.publicPath,
      route: null,
    };
  },
  name: "AdminSidebar",
  components: {},
  methods: {
    ...mapActions("Profiles", ["getProfile"]),
    setImg(meta) {
      let url = this.app_url;
      if (url.charAt(url.length - 1) == "/") url = url.slice(0, url.length - 1);
      return url + "/" + meta;
    },
  },
  mounted() {
    const token = Cookies.get(TOKEN);
    if (token) this.getProfile();
  },
  computed: {
    ...mapGetters("Profiles", ["profile"]),

    cr() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.dropdown-menu {
  background: #3396a1;
}
.submenu{
  padding: 8px 20px;
}
</style>
