<template>
  <AdminHeader v-if="!$route.meta.hideNavbar"/>
  <AdminSidebar v-if="!$route.meta.hideNavbar" />
  <router-view />
</template>

<script>
// @ is an alias to /src
import AdminHeader from "@/components/AdminHeader";
import AdminSidebar from "@/components/AdminSidebar";
import globalMixin from "@/mixins/globals";

export default {
  name: "App",
  components: {
    AdminHeader,
    AdminSidebar,
  },
  mixins: [globalMixin]
};
</script>

<style>
</style>
