import { createApp } from 'vue'
import App from './App.vue'
import router from '../../router'
import store from '../../store/index';
import globalMixin from "@/mixins/globals";
import CKEditor from '@ckeditor/ckeditor5-vue';

const app= createApp(App);
app.use(router);
app.use(store);
app.use(CKEditor );
app.mixin(globalMixin);
app.mount('#app');
